import logo from './logo.svg';
import './App.css';
import Root from './components/Root';

function App() {
  return (
    <Root/>
  );
}

export default App;
