import { Button, Card, CardActionArea, CardContent, CardHeader, IconButton, Menu, MenuItem, Step, StepContent, StepLabel, Stepper, Table, TextField, Typography, css, styled } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import StepperNavigation from "./StepperNavigation";
import { read, utils } from "xlsx";
import { ArrowBackIos, ArrowForwardIos, DoneAll, Warning, WhatsApp } from "@mui/icons-material";
import usePrevious from "../hooks/usePrevious";
import { Transition, animated } from 'react-spring';


const StyledStepper = styled(Stepper)(
    ({ theme }) => 
    css`
        min-width: 40vw;
        margin: ${theme.spacing(1)} ${theme.spacing(3)}; 
    `
);


const StyledContent = styled("div")(
    ({ theme }) => 
    css`   
        display: grid;
        grid-template-columns: 1fr;
        padding: ${theme.spacing(2)}  ${theme.spacing(0)};
        gap: ${theme.spacing(2)};
    `
);

const StyledValidation = styled(Card)(
    ({ theme }) => 
    css`   
        display: grid;
        grid-template-columns: auto 1fr;
        padding: ${theme.spacing(2)};
        gap: ${theme.spacing(2)};
        border-color: ${theme.palette.error.main}; 
        align-items: center;
        justify-items: center;

        > * {
            color: red;
        }

        div {
            grid-column: 1 / 3;
        }
    `
);


const StyledConfirmation = styled("div")(
    ({ theme }) => 
    css`   
        display: grid;
        grid-template-columns: auto 1fr;
        padding: ${theme.spacing(2)} ${theme.spacing(4)};
        gap: ${theme.spacing(1)};
        align-items: center;
        justify-items: stretch;

        > * {
            color: ${theme.palette.success};
        }

        > .value {
            text-align: right;
            font: ${theme.typography.subtitle1};
        }

        > .header {
            text-align: center;
            font: ${theme.typography.h5};
        }

    `
);



const StyledWarningMessage = styled(Typography)(
    () => 
    css`   
        color: red;
    `
);

const StyledEditor = styled('div')(
    ({ theme }) => 
    css`   
        .MuiInputBase-root {
            display: grid;
            grid-template-columns: 1fr;
            padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
            gap: ${theme.spacing(1)};
        }
    `
);


const StyledToolBar = styled('div')(
    ({ theme }) => 
    css`   
        display: grid;
        grid-template-columns: auto auto auto 1fr;
        padding: ${theme.spacing(0.25)};
        gap: ${theme.spacing(1)};
        align-items: center;
        justify-items: flex-start;

        border-style: solid;
        border-width: 0 0 1px 0;
        border-color: ${theme.palette.divider};
        background-color: ${theme.palette.background.default}; 
        box-shadow: ${theme.shadows[0]};
        
    `
);

const StyledButtonBox = styled("div")(
    ({ theme }) => 
    css`   
        padding: ${theme.spacing(1)};
    `
);

const StyledPreviewPane = styled('div')(
    ({ theme }) => 
    css`   
       width: 350px;
       max-width: 90%;
       display: grid;
        grid-template-columns: auto 1fr auto;
        padding: ${theme.spacing(2)};
        gap: ${theme.spacing(2)};
        align-items: center;
        justify-items: stretch;
        
    `
);

const StyledPreview = styled('div')(
    ({ theme }) => 
    css` 
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        .MuiCardContent-root {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto;
            background-color: ${theme.palette.divider};

            *:nth-of-type(1){
                white-space: pre-wrap;
                min-height: ${theme.spacing(15)};
            }

            *:nth-of-type(2){
                align-self: center;
            }
        }

        .MuiCardActionArea-root {
            font: ${theme.typography.button};
            text-align: center;
            padding: ${theme.spacing(1)};
        }

        > .MuiTypography-root {
            font: ${theme.typography.caption};
            text-align: center;
            padding: ${theme.spacing(1)};
        }
    `
);


const validMessage = 'Data is valid, submit to finish.'

const transitionConfig = { mass: 1, tension: 210, friction: 20, clamp: true }

const InsertMenu = (props) => {
    const {onAction, fields} = props

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleMenu = (field) => {
        onAction(`‹‹${field}››`)  
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                variant='outlined'
                onClick={handleClick}
            >
                Insert Field
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    fields.map((field, index)=>(
                        <MenuItem key={index} onClick={e=>handleMenu(field)}>{field}</MenuItem>
                    ))
                }
                
            </Menu>
        </div>
    );
}


const PreviewPane = props => {
    const {data, index, template} = props
    //const {currentRow, previewMessage, index, length} = props
    const [preview, setPreview] = useState("");

    
    const updatePreview = (index, template) => {

        const columns = Object.keys(data[index])
        
        let temp = (' ' + template).slice(1);

        columns.forEach(col=>{
            temp = temp.replace(new RegExp(`‹‹${col}››`, 'g'), data[index][col])
        })

        setPreview(temp)
     
    }

    useEffect(()=>{
        updatePreview(index, template)
    }, [index, template])

    return (
        <StyledPreview>
            <Card variant='outlined'>
                {
                    Boolean(data[index]) && 
                    <CardHeader
                        subheader={`Phone : ${data[index]['Phone']}`}
                    />
                }
                <CardContent>
                    <Typography>{preview}</Typography>                                        
                </CardContent>
                <CardActionArea disableRipple>                
                    <Button 
                        startIcon={<WhatsApp/>}
                        target="_blank"
                        href={`https://wa.me/${data[index]['Phone']}?text=${preview}`}
                    >
                        Send to WhatApp
                    </Button>
                </CardActionArea>
            </Card>
            <Typography variant='body1'>{`${index+1} of ${data.length}`}</Typography>
        </StyledPreview>
        

    )
}

const Messager = (props) => {
    const [activeStep, setActiveStep] = useState(0);
    const [workBook, setWorkBook] = useState()
    const [excelSheets, setExcelSheets] = useState([]);
    const [sheet, setSheet] = useState(''); 
    const [patients, setPatients] = useState([])
    const [columns, setColumns] = useState([])
    const [validation, setValidation] = useState("");
    const [validationComplete, setValidationComplete] = useState(false);
    const [description, setDescription] = useState("")
    const [template, setTemplate] = useState("");

    const [previewIndex, setPreviewIndex] = useState(0);
    const [indexArray, setArray] = useState([previewIndex, previewIndex+1, previewIndex+2])

    
    const prevPreviewIndex = usePrevious(previewIndex)

    const pages = [
        <PreviewPane index={indexArray[0]} data={patients} template={template} />,
        <PreviewPane index={indexArray[1]} data={patients} template={template}/>,
        <PreviewPane index={indexArray[2]} data={patients} template={template}/>
    ]

    const transformations = {
        from: {transform: prevPreviewIndex < previewIndex ? 'translate3d(100%,0,0)' : 'translate3d(-100%,0,0)'},
        enter: {transform: 'translate3d(0%,0,0)'},
        leave: {transform: prevPreviewIndex < previewIndex ? 'translate3d(-100%,0,0)' : 'translate3d(100%,0,0)'}
    }

    const inputRef = useRef()
    const editorRef = useRef(null)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
       

    const readFile = evt => {
        const filelist = evt.target.files
        
        setValidationComplete(false)

        if(filelist.length){
            const file = evt.target.files[0];            

            const reader = new FileReader();

            reader.readAsArrayBuffer(file);
            reader.onload = (e) => {

                // upload file
                const binarystr = new Uint8Array(e.target.result);
                const wb =  read(binarystr, { type: 'array', raw: true, cellFormula: true });
                const sheetNames = wb.SheetNames;

                setWorkBook(wb)
                setExcelSheets(sheetNames)

                if(sheetNames.length){
                    setSheet(sheetNames[0])
                    readAndValidateSheet(wb, sheetNames[0])
                }
            }
        }else{
            setWorkBook(null)
            setExcelSheets([])
            setSheet('')
            setValidation("")
        }
        
    }


    const readAndValidateSheet = (wb, sheetName) => {
        const data = utils.sheet_to_json(wb.Sheets[sheetName]);
        validateSheetData(data)
    }

    const validateSheetData = data => {
        if(!Array.isArray(data)){
            setValidation("Data provided is not valid")
            return;
        }

        let errorMessage = ''

        if(data.length ===  0){
            errorMessage = 'No records found'
            return true
        }

        let invalidIndex = data.findIndex(item=>{

            let phone = item['Phone'] || 0

            if(!Boolean(phone)){
                errorMessage = `Invalid or missing phone number`
                return true
            }

            
            
        })

        let finalMessage =  invalidIndex > -1? `Row ${invalidIndex+1} : ${errorMessage}` : validMessage


        setValidation(finalMessage)

        if(finalMessage === validMessage){           
            

            const regex = /\d{9}$/
            const updated = data.map(pt=>{                
                return {
                    ...pt, 
                    Phone: `254${regex.exec(pt['Phone'].split(',')[0].trim().replace(/\D/g,''))}`
                }
            })

            setPatients(updated)
            setColumns(Object.keys(updated[0]))
        }

        setValidationComplete(true)
        
    }


    const insertText = (text) => {
        
        editorRef.current.focus()

        const textarea = editorRef.current.getElementsByTagName("textarea")[0]
        const curPos = textarea.selectionStart
        
        setTemplate(template.slice(0,curPos)+text+template.slice(curPos))

     
    } 


    return (
        <div>
            <StyledStepper activeStep={activeStep} orientation="vertical"  >

                <Step>
                    <StepLabel>Upload excel data source</StepLabel>
                    <StepContent>

                        <StyledContent>
                            <input
                                placeholder='Select file...'
                                type={'file'}
                                accept=".xlsx, .xls"
                                onChange={readFile}
                                onClick={e=>e.target.value = null} 
                                ref={inputRef}
                            />

                            {
                                Boolean(workBook) &&
                                <>
                                    <TextField
                                        required
                                        label={"Excel Sheet"}                                        
                                        select
                                        value={sheet}
                                        onChange={e=>{
                                            setSheet(e.target.value)
                                            readAndValidateSheet(workBook, e.target.value)
                                        }}
                                    >
                                        {excelSheets.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    {
                                        validation !== validMessage && 
                                        <StyledValidation variant='outlined'>
                                            <Warning/>
                                            <StyledWarningMessage>{validation}</StyledWarningMessage>
                                        </StyledValidation>
                                    }


                                    {
                                        (validation === validMessage && validationComplete) &&
                                        <StyledConfirmation variant='outlined'>
                                            <DoneAll/>
                                            {`${patients.length} valid records`}
                                        </StyledConfirmation>                             
                                        

                                    }
                                </>
                                

                                
                            }

                            
                        </StyledContent>
                        
                        <StepperNavigation>
                            <Button size='small'  variant="contained" onClick={handleNext} disableElevation >
                                Continue
                            </Button>
                        </StepperNavigation>
                    </StepContent>
                </Step>

                <Step >
                    <StepLabel>Prepare SMS template (and insert variable fields)</StepLabel>
                    <StepContent>

                        <StyledEditor>
                            <TextField
                                placeholder='Write template here...'
                                multiline
                                fullWidth
                                minRows={2}
                                ref={editorRef}
                                value={template}
                                onChange={e => setTemplate(e.target.value)}

                                InputProps={{
                                    startAdornment: <StyledToolBar>
                                        <InsertMenu
                                            onAction={insertText}
                                            fields={columns}
                                        />

                                        {/* <InsertTemplate
                                            onAction={insertTemplate}
                                            onDelete={deleteTemplate}
                                            templates={templates}
                                        /> */}
                                    </StyledToolBar>
                                }}
                            />
                        </StyledEditor>

                        <StyledButtonBox>
                            <Button size='small' variant="contained" onClick={handleNext} >
                                Continue
                            </Button>
                            <Button size='small' onClick={handleBack}>
                                Back
                            </Button>
                        </StyledButtonBox>
                    </StepContent>
                </Step>

                <Step >
                    <StepLabel>Preview merged messages and send</StepLabel>
                    <StepContent>
                        <StyledPreviewPane>
                            <IconButton onClick={e => { setPreviewIndex(previewIndex > 0 ? previewIndex - 1 : previewIndex) }} disabled={previewIndex === 0}>
                                <ArrowBackIos fontSize="inherit" />
                            </IconButton>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: '1fr', overflow: 'hidden' }}>
                                <Transition
                                    native
                                    unique
                                    config={transitionConfig}
                                    items={previewIndex}
                                    from={transformations.from}
                                    enter={transformations.enter}
                                    leave={transformations.leave}>
                                    {(styles, index) => (
                                        <animated.div
                                            style={{
                                                ...styles,
                                                gridColumn: '1 / 2',
                                                gridRow: '1 /2'
                                            }}>
                                            {pages[index % 3]}
                                        </animated.div>

                                    )}
                                </Transition>
                            </div>
                            <IconButton onClick={e => { setPreviewIndex(previewIndex < patients.length - 1 ? previewIndex + 1 : previewIndex) }} disabled={previewIndex === patients.length - 1}>
                                <ArrowForwardIos fontSize="inherit" />
                            </IconButton>
                        </StyledPreviewPane>



                        <StyledButtonBox>
                            
                            <Button size='small' onClick={handleBack}>
                                Back
                            </Button>
                        </StyledButtonBox>
                    </StepContent>
                </Step> 
            </StyledStepper>
        </div>
    )
};
export default Messager;