

import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';
import React from "react";




const StyledRoot = styled('div')(
    ({ theme }) => 
    css`
        display: flex;
        flex-direction: row;
        gap: ${theme.spacing(2)}; 
        padding: ${theme.spacing(1)}; 
    `
);


const StepperNavigation = (props) => (
    <StyledRoot>{props.children}</StyledRoot>
);

export default StepperNavigation;